<template>
  <div class="demo-wrapper">
    <!--START: Header-->
    <header>
      <router-link to="/">
        <img src="@/assets/images/logo-white.png" class="logo"
      /></router-link>
    </header>
    <!--END: Header-->

    <div class="demo-form-wrapper">
      <div class="gradient-circle"></div>

      <!--START: Info Wrapper-->
      <div class="info-wrapper">
        <h1 class="main-title" v-html="title[contactType]"></h1>
        <div class="inclusions-list desktop">
          <label>Along with all key features, you also get the following</label>
          <div v-for="item in inclusions" :key="item.id" class="inclusion">
            <unicon name="check"></unicon>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <!--END: Info Wrapper-->

      <!--START: Contact Form-->
      <DemoForm></DemoForm>
      <!--END: Contact Form-->

      <!--START: Mobile Inclusions-->
      <div class="inclusions-list mobile">
        <label>Along with all key features, you also get the following</label>
        <div v-for="item in inclusions" :key="item.id" class="inclusion">
          <unicon name="check"></unicon>
          <span>{{ item }}</span>
        </div>
      </div>
      <!--END: Mobile Inclusions-->
    </div>

    <!--START: Brands-->
    <div class="brands-wrapper">
      <BrandWall class="logos"></BrandWall>
      <span>Trusted by brands to provie amazing AI support</span>
    </div>
    <!--END: Brands-->

    <!--START: Customer Story-->
    <CustomerStory></CustomerStory>
    <!--END: Customer Story-->

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
    
<script>
//Import components
import Footer from "@/components/Footer";
import DemoForm from "@/components/Forms/DemoForm";
import BrandWall from "@/components/Home/BrandWall";
import CustomerStory from "@/components/Home/CustomerStory";

export default {
  name: "CreateDemo",
  data() {
    return {
      title: {
        demo: "Start your <b>free trial</b>",
        register:
          "Start your <b>free trial</b><i>Fully assisted</i> onboarding",
        sales: "Start your <b>free trial</b><i>Fully assisted</i> onboarding",
      },
      inclusions: [
        "Onboarding support from our co-founder",
        "Conversations analysis and audit",
        "Post-analysis optimization and refinement",
        "30-days money back guarantee",
      ],
    };
  },
  components: {
    DemoForm,
    BrandWall,
    CustomerStory,
    Footer,
  },
  computed: {
    contactType() {
      return this.$store.getters.demoData.contactType;
    },
  },
  async created() {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
.demo-wrapper {
  position: relative;
  background-color: $whiteColor;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 3rem;

  .logo {
    filter: invert(1);
    width: 6.5rem;
  }

  .login-wrapper {
    flex: 1;
    text-align: right;

    span {
      margin-right: 1.5rem;
      opacity: $lightOpacity;
    }
  }
}

.main-title {
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.5rem;
  line-height: 1.25;

  :deep(b) {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    margin-bottom: -0.5rem;
  }
}
.gradient-circle {
  position: absolute;
  background-color: rgb(167, 138, 250);
  right: -45%;
  border-radius: 50%;
  top: calc(50% + 6rem);
  transform: translateY(-50%);
  height: 55%;
  width: 90%;
  filter: blur(100px);
  transform: translate3d(0, 0, 0);

  opacity: 0.5;
}

.demo-form-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 4rem 15%;

  .info-wrapper {
    width: 50%;
    margin-right: 5rem;
  }

  .form-wrapper {
    flex: 1;
  }
}

.inclusions-list {
  margin-top: 2rem;
  label {
    display: block;
    font-weight: $mediumFontWeight;
    font-size: $normalFontSize;
    margin-bottom: 2.5rem;
  }
  .inclusion {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  :deep(svg) {
    fill: darken($emeraldColor, 30%);
    width: auto;
    height: 1rem;
    margin-right: 1rem;
    background: lighten($emeraldColor, 25%);
    border-radius: 50%;
    padding: 2px;
  }
}

.brands-wrapper {
  margin: 8rem auto 8rem;

  span {
    font-size: $smallerFontSize;
    display: block;
    text-align: center;
    color: $blackColor;
    opacity: $lightOpacity;
    margin-top: 2rem;
  }

  .logos {
    :deep(img) {
      filter: invert(1);
    }
  }
}

.testimonial-wrapper {
  margin-top: 4rem;
  margin-bottom: 5rem;
}

.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  header {
    padding: 1.5rem 1.5rem;

    .login-wrapper {
      span {
        display: none;
      }
    }
  }

  .demo-form-wrapper {
    display: block;
    margin: 2rem 1.5rem 1rem;

    .info-wrapper {
      width: auto;
      margin: 0;
    }
  }

  .main-title {
    font-size: 2.25rem;
    margin-bottom: 2rem;
    text-align: center;

    :deep(b) {
      font-size: 3.5rem;
    }
  }

  .inclusions-list {
    label {
      margin-bottom: 1.5rem;
    }
  }

  .inclusions-list {
    margin-top: 3rem;
  }

  :deep(.brands-wrapper) {
    margin-top: 5rem;
  }
}
</style>