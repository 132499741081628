<template>
  <div>
    <!--START: Header and Gradient-->
    <Header :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <!--START: Study Wraper-->
    <div class="study-wrapper">
      <!--START: Hero-->
      <div class="hero-wrapper">
        <div class="title-wrapper">
          <div class="title-block">
            <span class="page-label">Customer Story</span>
            <h1>Move on.</h1>
          </div>
          <img
            src="@/assets/images/customers/mokobara/logo.png"
            alt="Mokobara logo"
          />
        </div>

        <div class="stats-wrapper">
          <div v-for="item in stats" :key="item" class="stat-item">
            <em>{{ item.stat }}</em>
            <p>{{ item.description }}</p>
          </div>
        </div>

        <span class="gradient-circle"></span>
      </div>
      <!--END: Hero-->

      <div class="hero-banner">
        <img
          src="@/assets/images/customers/mokobara/hero.jpg"
          alt="Mokobara Hero"
        />
      </div>

      <!--START: About-->
      <div class="about-wrapper">
        <div class="title-wrapper">
          <h3 class="title">About Mokobara</h3>
          <a href="https://mokobara.com/" target="_blank">
            <unicon name="external-link-alt"></unicon>
            <span>Visit Website</span>
          </a>
          <div class="tools-wrapper">
            <label>Customer Support Tools</label>
            <img src="@/assets/images/logos/freshdesk.png" alt="Zendesk" />
            <img src="@/assets/images/logos/whatsapp.png" alt="Zendesk" />
          </div>
        </div>
        <div class="about-info">
          <div class="description">
            <p>
              Mokobara: A D2C startup that’s making premium travel bags
              affordable. It offers thoughtfully designed luggage bags,
              backpacks, and travel accessories that appeal to the aesthetic
              sensibilities of the modern traveller. It also offers wallets and
              accessories such as packing cubes and neck pillows. Along the way,
              the company raised Series A funding of $6.5 million in a round led
              by Saama Capital, Sauce VC, Alteria Capital, and Panthera Peak
              Ventures.
            </p>
          </div>
        </div>
      </div>
      <!--END: About-->

      <!--START: Overview-->
      <div class="overview-wrapper">
        <span class="gradient-circle"></span>
        <h3 class="title">Move in style</h3>
        <p class="description">
          Despite having a user-friendly website and more than 10 support
          agents, the brand was becoming overwhelmed by basic pre-sales and
          post-sales inquiries. They needed a solution that could efficiently
          handle these customer interactions without negatively impacting their
          NPS scores.
        </p>
        <ul class="overview-list">
          <li v-for="item in overviewList" :key="item" class="overview-item">
            {{ item }}
          </li>
        </ul>
      </div>
      <!--END: Overview-->

      <!--START: Challenge-->
      <div class="challenge-wrapper">
        <h3 class="title">The Challenge</h3>
        <div class="description">
          <p>
            Mokobara relied on WhatsApp as their primary support channel.
            However, they were overwhelmed by a high volume of basic inquiries,
            including order tracking and pre-sale questions about their product
            features. Despite having a team of 10 support agents, the increasing
            demand was pushing them to consider hiring more staff. They needed a
            solution that could automate parts of their support processes to
            prevent their agents from being inundated with routine queries that
            didn’t require human intervention. Additionally, they sought a
            system that could reflect the innovative nature of their products.
          </p>
        </div>
        <img
          src="@/assets/images/customers/mokobara/asset.png"
          alt="Subimods Rim"
          class="asset"
        />
      </div>
      <!--END: Challenge-->

      <!--START: Solution-->
      <div class="solution-wrapper">
        <h3 class="title">The Solution</h3>
        <div class="solutions-list">
          <div v-for="item in solutionsList" :key="item" class="solution-item">
            <div class="title-wrapper">
              <unicon :name="item.icon" />
              <h3>{{ item.title }}</h3>
            </div>

            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
      <!--END: Solution-->

      <!--START: Quote-->
      <div class="quote-wrapper">
        <em>“</em>
        <div class="quote">
          <p>
            We noticed a recurring pattern in many customer queries, especially
            in chats, and we didn't want to hire a large team to manage them.
            That's when we turned to AI and found Macha.
          </p>
          <p>
            As one of their early clients, we quickly automated about a large
            portion of our queries, reaching nearly 70% during peak times like
            sales and festivals. The support and dedication from the Macha team
            have been exceptional.
          </p>
        </div>
        <div class="profile-wrapper">
          <img
            src="@/assets/images/customers/mokobara/profile.jpg"
            alt="Nick Nazzaro"
          />
          <div class="profile">
            <h3>Shiro Jacob</h3>
            <span>Head of Customer Experience</span>
          </div>
        </div>
      </div>
      <!--END: Quote-->
    </div>
    <!--END: Study Wraper-->

    <div class="standard-footer">
      <!--START: Onboarding-->
      <div class="onboarding-wrapper">
        <div class="title-gradient"></div>

        <h2 class="main-title">
          Go live effortlessly with our
          <i>team supporting you at every step</i>
        </h2>

        <a
          href="https://cal.com/teammacha/macha-ai-demo"
          target="_blank"
          class="gradient-word gradient-title-header gradient-title-header-light gradient-btn"
        >
          Book a demo with our team
        </a>
      </div>
      <!--END: Onboarding-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
            
    <script>
// Import components
import CustomerStory from "@/components/Home/CustomerStory";
import SupportStatistics from "@/components/Home/SupportStatistics";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "MokobaraCaseStudy",
  data() {
    return {
      headerTheme: "dark",
      stats: [
        {
          stat: "90%",
          description: "of enquiries responded by AI",
        },
        {
          stat: "80%+",
          description: "AI conversations require no human agent",
        },
        {
          stat: "90%",
          description: "reduction in WISMO and basic product queries",
        },
      ],
      overviewList: [
        "70%+ of full resolution rate",
        "$12,000 project savings",
        "Seamless handoff to Whatsapp for more complex queries",
      ],
      solutionsList: [
        {
          icon: "exchange",
          title: "Seamless Automation",
          description:
            "Macha AI efficiently handles 90%+ of all incoming website inqueries across various languages and regions, delivering comprehensive resolutions for over 70% of them.",
        },
        {
          icon: "bolt",
          title: "AI-Powered Issue Detection",
          description:
            "Macha AI delivers exceptional accuracy, reaching 99%, in identifying the right customer issue and handling post sales and e-commerce queries.",
        },
        {
          icon: "lightbulb-alt",
          title: "Scalable Solution",
          description:
            "Macha AI offers a scalable, 24/7 customer support solution that’s in line with their business growth, ensuring responsive and efficient service across key markets.",
        },
        {
          icon: "comment-alt",
          title: "Proactive Automation",
          description:
            "Macha AI's ability to handle customer support tickets in multiple languages (120+ major world languages) demonstrates it’s capacity to cater to diverse customer needs effectively.",
        },
      ],
    };
  },

  components: {
    CustomerStory,
    SupportStatistics,
    Header,
    Footer,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "hero":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
            
<style scoped lang="scss">
@import "@/assets/styles/shared/customers.scss";

.challenge-wrapper {
  background-color: #e6b100;

  .title,
  .description p {
    color: #201a05;
  }

  .asset {
    width: 15rem;
    right: 10rem;
  }
}

@media screen and (max-width: 800px) {
  .challenge-wrapper {
    padding-bottom: 10rem;

    .asset {
      right: 0;
      left: 0;
      bottom: -2rem;
      margin: auto;
      width: 11rem;
    }
  }
}
</style>