<template>
  <div>
    <!--START: Header and Gradient-->
    <Header :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <!--START: Study Wraper-->
    <div class="study-wrapper">
      <!--START: Hero-->
      <div class="hero-wrapper">
        <div class="title-wrapper">
          <div class="title-block">
            <span class="page-label">Customer Story</span>
            <h1>Flex on.</h1>
          </div>
          <img
            src="@/assets/images/customers/brandl/logo.png"
            alt="Brandl logo"
          />
        </div>

        <div class="stats-wrapper">
          <div v-for="item in stats" :key="item" class="stat-item">
            <em>{{ item.stat }}</em>
            <p>{{ item.description }}</p>
          </div>
        </div>

        <span class="gradient-circle"></span>
      </div>
      <!--END: Hero-->

      <div class="hero-banner">
        <img
          src="@/assets/images/customers/brandl/hero.jpg"
          alt="Brandl Hero"
        />
      </div>

      <!--START: About-->
      <div class="about-wrapper">
        <div class="title-wrapper">
          <h3 class="title">About Brandl</h3>
          <a href="https://brandl-nutrition.de/" target="_blank">
            <unicon name="external-link-alt"></unicon>
            <span>Visit Website</span>
          </a>
          <div class="tools-wrapper">
            <label>Shopify Tools</label>
            <img src="@/assets/images/logos/shopify-inbox.png" alt="Shopify Inbox" />
            <img src="@/assets/images/logos/recharge.png" alt="Recharge" />
          </div>
        </div>
        <div class="about-info">
          <div class="description">
            <p>
              Brandl Nutrition is a German company that makes protein shakes and
              other sports nutrition supplements for health-conscious athletes.
              Brandl Nutrition makes shakes that are individually mixed based on
              science and a focus on health. Their products are based on science
              and are confirmed to be effective by studies. Brandl Nutrition
              products are independently lab tested and products are made with
              natural ingredients, free from chemicals.
            </p>
          </div>
        </div>
      </div>
      <!--END: About-->

      <!--START: Overview-->
      <div class="overview-wrapper">
        <span class="gradient-circle"></span>
        <h3 class="title">Fuel the burn</h3>
        <p class="description">
          Brandl Nutrition, one of Germany’s top protein supplement brands,
          recognized a recurring issue despite their website's excellent UI/UX:
          customers were frequently seeking post-purchase support, such as
          tracking orders or managing subscriptions. To address this, Brandl
          sought an AI-driven solution that could not only provide product
          information but also offer immediate assistance with post-purchase
          inquiries.
        </p>
        <ul class="overview-list">
          <li v-for="item in overviewList" :key="item" class="overview-item">
            {{ item }}
          </li>
        </ul>
      </div>
      <!--END: Overview-->

      <!--START: Challenge-->
      <div class="challenge-wrapper">
        <h3 class="title">The Challenge</h3>
        <div class="description">
          <p>
            Brandl Nutrition, Germany's leading health and nutrition brand,
            offers a wide range of supplements and products, many of which are
            available through subscription. They faced numerous customer support
            requests related to subscription and order management, such as
            pausing or cancelling subscriptions, changing products, updating
            addresses or payment methods, tracking orders, or adjusting delivery
            dates; especially from customers who did a guest checkout. Despite
            having a subscription management tool, Recharge, many customers were
            not using the dashboard to manage these tasks independently. To
            improve the overall order management experience,
            <b
              >Brandl partnered with Macha AI to integrate Shopify and Recharge
              into a unified system, enhancing the customer experience.</b
            >
          </p>
        </div>
        <img
          src="@/assets/images/customers/brandl/asset.png"
          alt="Brandl packet"
          class="asset"
        />
      </div>
      <!--END: Challenge-->

      <!--START: Solution-->
      <div class="solution-wrapper">
        <h3 class="title">The Solution</h3>
        <div class="solutions-list">
          <div v-for="item in solutionsList" :key="item" class="solution-item">
            <div class="title-wrapper">
              <unicon :name="item.icon" />
              <h3>{{ item.title }}</h3>
            </div>

            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
      <!--END: Solution-->

      <!--START: Quote-->
      <div class="quote-wrapper">
        <em>“</em>
        <div class="quote">
          <p>
            Partnering with Macha AI has been a game-changer for Brandl
            Nutrition. Managing customer orders and subscriptions used to be a
            time-consuming process, but the Macha AI widget has simplified
            everything for our customers. Now, they can easily manage their
            subscriptions and orders without any hassle, all through a seamless
            interface.
          </p>
          <p>
            What makes Macha AI particularly valuable to us is its flawless
            integration with both Shopify and Recharge, which are core to our
            business operations. This has allowed us to offer our customers a
            much smoother experience while also saving us time and resources on
            the backend.
          </p>
          <p>
            We’re grateful for Macha AI’s solution—it’s been instrumental in
            elevating our customer service and improving operational efficiency.
          </p>
        </div>
        <div class="profile-wrapper">
          <img
            src="@/assets/images/customers/brandl/profile.jpg"
            alt="René Seppeur"
          />
          <div class="profile">
            <h3>René Seppeur</h3>
            <span>Founder & CEO</span>
          </div>
        </div>
      </div>
      <!--END: Quote-->
    </div>
    <!--END: Study Wraper-->

    <div class="standard-footer">
      <!--START: Onboarding-->
      <div class="onboarding-wrapper">
        <div class="title-gradient"></div>

        <h2 class="main-title">
          Go live effortlessly with our
          <i>team supporting you at every step</i>
        </h2>

        <a
          href="https://cal.com/teammacha/macha-ai-demo"
          target="_blank"
          class="gradient-word gradient-title-header gradient-title-header-light gradient-btn"
        >
          Book a demo with our team
        </a>
      </div>
      <!--END: Onboarding-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
          
<script>
// Import components
import CustomerStory from "@/components/Home/CustomerStory";
import SupportStatistics from "@/components/Home/SupportStatistics";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "BrandlCaseStudy",
  data() {
    return {
      headerTheme: "dark",
      stats: [
        {
          stat: "80%",
          description: "of order-management tickets handled by AI",
        },
        {
          stat: "60%+",
          description: "of subscription management tickets handled by AI",
        },
        {
          stat: "90%",
          description: "more tickets handled with same number of agents",
        },
      ],
      overviewList: [
        "70%+ of all order management tickets resolved without agents",
        "Deep Shopify and Recharge Subscriptions integration to solve subscription management",
        "A unified support channel to handle all post-purchase support",
      ],
      solutionsList: [
        {
          icon: "exchange",
          title: "Frictionless Automation",
          description:
            "Macha AI integrated with Shopify and Recharge to offer a frictionless order and subscription management experience to customers through a unified customer portal.",
        },
        {
          icon: "bolt",
          title: "No More Logins",
          description:
            "Most customers found it hard to login and manage their orders. The ones who did a guest checkout found it even more challenging. Macha AI resolved this with a simple email OTP authentication for all order management actions.",
        },
        {
          icon: "lightbulb-alt",
          title: "High Success Rate",
          description:
            "Macha AI is now able to handle over 90% of all order management queries coming in with minimal human intervention. ",
        },
        {
          icon: "cog",
          title: "Growth Without Hiring",
          description:
            "Macha AI’s success in managing customer interactions allows Brandl to scale its business without needing to proportionally expand its support team, effectively separating business growth from increases in agent headcount.",
        },
      ],
    };
  },

  components: {
    CustomerStory,
    SupportStatistics,
    Header,
    Footer,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "hero":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
          
  <style scoped lang="scss">
@import "@/assets/styles/shared/customers.scss";

.challenge-wrapper {
  background-color: #4fe3c1;

  .title,
  .description p {
    color: #033025;
  }

  .asset {
    width: 18.5rem;
    right: 5rem;
  }
}

@media screen and (max-width: 800px) {
  .challenge-wrapper {
    padding-bottom: 10rem;

    .asset {
      right: 0;
      left: 0;
      bottom: -3rem;
      margin: auto;
      width: 6rem;
    }
  }
}
</style>