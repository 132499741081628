<template>
  <div class="hero-wrapper">
    <!--START: Info Wrapper-->
    <div class="info-wrapper">
      <div class="badge">
        <img src="@/assets/images/logos/open-ai-mark.png" alt="OpenAI logo" />
        <span>Powered by GPT-4o from OpenAI</span>
      </div>
      <h1 class="title">
        <span class="gradient-word">AI Zendesk App</span><br />
        built for <i>your support agents</i>
      </h1>
      <p class="subtitle">
        Generate replies with translations and brand tone, using help center and
        macros
      </p>
      <button class="btn btn-primary" @click="createAccount">
        <unicon name="external-link-alt"></unicon>
        <span>Add to Zendesk for free</span>
      </button>
      <span class="demo-info">
        No credit card. First 50 replies are absolutely free.
      </span>
    </div>
    <!--END: Info Wrapper-->

    <!--START: Line Animation-->
    <LineAnimation></LineAnimation>
    <LineAnimation class="line-right"></LineAnimation>
    <!--END: Line Animation-->

    <!--START: Video Frame-->
    <div class="video-player-container">
      <label>Watch the Zendesk widget walkthrough</label>
      <iframe
        src="https://www.loom.com/embed/f63de53ee3ce478c8570c849b1c0cd31?sid=4be3a2bf-fb0d-4b91-9bb9-8ea9b4a7f570"
        class="video-player"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allowtransparency
      ></iframe>
    </div>
    <!--END: Video Frame-->

    <!--START: Gradient Background-->
    <GradientBackground></GradientBackground>
    <!--END: Gradient Background-->
  </div>
</template>
      
<script>
//Import components
import GradientBackground from "@/components/GradientBackground";
import LineAnimation from "@/components/Home/LineAnimation";

export default {
  name: "ZendeskHero",
  components: {
    GradientBackground,
    LineAnimation,
  },
  methods: {
    createAccount() {
      const data = {
        event: "create_account",
        source: { source: "zendesk_hero" },
      };
      this.$emit("createAccount", data);
    },
  },
};
</script>
      
<style scoped lang="scss">
.hero-wrapper {
  position: relative;
  padding: 7rem 10% 0rem;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    background-image: url(@/assets/images/noise.webp);
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    height: calc(100% - 10rem);
    top: 0;
    left: 0;
    opacity: 0.85;
  }

  &:hover {
    .gradient-color {
      animation-play-state: running;
    }
  }

  .btn {
    font-size: 1.15rem;
    margin-top: 2.5rem;
  }

  .chat-container {
    position: relative;
    flex: 1;
    width: 25rem;
    margin-right: 5rem;
  }

  .info-wrapper {
    position: relative;
    margin: 1rem auto 0;
    text-align: center;
    width: 70%;
  }

  :deep(.gradient-container) {
    height: calc(100% - 10rem);

    .gradient-color {
      animation: none;
    }
  }

  .demo-info {
    display: block;
    margin-top: 0.75rem;
    color: #958bb0;
  }
}

.video-player-container {
  position: relative;
  display: block;
  width: 60vw;
  height: calc(586 / 1045 * 60vw);
  margin: 4.5rem auto -10rem;

  label {
    display: block;
    color: #cbbbe0;
    margin: 0 auto;
    text-align: center;
    font-size: $smallerFontSize;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
  }

  .video-player {
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    transform: translateY(2.5rem);
    box-shadow: 0 1rem 2.7rem 0.5rem rgb(140 91 199 / 40%);
    border-radius: 1.5rem;
    border: none;
  }
}

.title {
  color: #e8eaed;
  font-weight: 300;
  letter-spacing: -0.05rem;
  line-height: 1;
  margin: 1.5rem 0 1rem;
  font-family: "Baskervville";
  font-size: 2.75rem;

  .gradient-word {
    font-size: 6rem;
  }
}

.badge {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0.5rem 1.25rem;
  border-radius: 1.5rem;
  border: 1px solid #66488c;
  margin: auto;
  background: #4d3568;

  img {
    display: inline-block;
    width: 1rem;
    margin-right: 0.5rem;
  }

  span {
    color: #e8ddf6;
    font-size: $smallFontSize;
  }
}

.subtitle {
  color: #e6ddff;
  font-family: $bodyFont;
  font-weight: 300;
  font-size: 1.25rem;
  letter-spacing: 0.015rem;
  line-height: 1.7;
  margin-top: 2rem;

  span {
    font-size: 2rem;
    margin-left: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: -5px;
  }

  b {
    color: $whiteColor;
    font-weight: $boldFontWeight;
  }
}

:deep(.line-container) {
  top: 20%;
  transform: none;

  &.line-right {
    transform: rotateZ(180deg);
  }
}

.mobile {
  display: none !important;
}

@media screen and (max-width: 800px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .hero-wrapper {
    flex-direction: column-reverse;
    padding: 7rem 1.5rem 10rem;

    .chat-container {
      width: 100%;
      margin-right: 0;
    }
    .info-wrapper {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;

      .badge {
        margin: auto;
      }
    }

    .gradient-container {
      height: 100%;
    }
  }

  .title {
    position: relative;
    font-size: 2rem;
    margin-bottom: 0;
    line-height: 1.2;

    .gradient-word {
      font-size: 3.5rem;
      margin-top: 0.5rem;
      letter-spacing: -3px;
      line-height: 1;
    }

    b {
      position: absolute;
      margin-right: 0;
      margin-left: 0;
      transform: none;
      right: 9vw;
      bottom: 2rem;
      font-size: 1.75rem;
    }
  }

  .line-container {
    display: none;
  }

  .subtitle {
    font-size: 1.05rem;
  }

  .video-player-container {
    width: 90vw;
    height: 50.475vw;
  }
}
</style>
      