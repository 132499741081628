<template>
  <div class="testimonial-wrapper">
    <!--START: Info Title-->
    <div class="info-title">
      <h2 class="main-title">
        <i>Happy customers</i> and their
        <b>Success Stories</b>
      </h2>

      <div class="navigation">
        <unicon name="angle-left-b" @click="storyCarousel.prev()"></unicon>
        <unicon name="angle-right-b" @click="storyCarousel.next()"></unicon>
      </div>
    </div>
    <!--END: Info Title-->

    <!--START: Carousel-->
    <Carousel ref="storyCarousel" :items-to-show="1.25" :wrap-around="true">
      <Slide v-for="story in caseStudies" :key="story.id">
        <div class="testimonial">
          <img
            :src="getTestimonialImage(story.image)"
            :alt="story.brand"
            class="testimonial-image"
          />

          <div class="info-wrapper">
            <img
              :src="getLogoImage(story.image)"
              :alt="story.brand"
              class="testimonial-logo"
            />
            <h3>{{ story.testimonial }}</h3>
            <div v-for="stat in story.stats" :key="stat.id" class="stat">
              <h4>{{ stat.title }}</h4>
              <p>{{ stat.description }}</p>
            </div>
            <a :href="story.articleLink" target="_blank">
              <span>Read case study</span>
              <unicon name="angle-right-b"></unicon
            ></a>
          </div>
        </div>
      </Slide>
    </Carousel>
    <!--END: Carousel-->
  </div>
</template>
        
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "CustomerStory",
  data() {
    return {
      caseStudies: [
        {
          brand: "Mokobara",
          image: "mokobara",
          testimonial:
            "Tames high ticket volumes and slow response times with Macha's AI self-serve portal, solving all L1 queries; and seamlessly hands off the rest to their live agents",
          stats: [
            {
              title: "5,000+",
              description: "L1 queries handled by AI per month",
            },
          ],
          articleLink: "https://www.getmacha.com/blog/mokobara-case-study/",
        },
        {
          brand: "Subimods",
          image: "subimods",
          testimonial:
            "Solved a unique WISMO challenge with Macha's AI self-serve portal; while empowering agents with actionable ticket insights on Zendesk",
          stats: [
            {
              title: "93.25%",
              description: "Containment rate for AI conversations",
            },
          ],
          articleLink: "https://www.getmacha.com/customers/subimods",
        },
        {
          brand: "Onsra",
          image: "onsra",
          testimonial:
            "Onsra uses Macha AI to serve their customers across 4 geographies, using multiple languages allowing customers to get instant answers",
          stats: [
            {
              title: "3+",
              description: "Languages used to provide support",
            },
          ],
          articleLink: "https://www.getmacha.com/blog/onsra-case-study/",
        },
      ],
    };
  },
  computed: {
    storyCarousel() {
      return this.$refs.storyCarousel;
    },
  },
  components: { Carousel, Slide },
  methods: {
    getTestimonialImage(image) {
      return require(`@/assets/images/testimonials/${image}.jpg`);
    },

    getLogoImage(image) {
      return require(`@/assets/images/logos/${image}.png`);
    },
  },
};
</script>
        
<style scoped lang="scss">
.info-title {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 12.5% 3rem;
}

.main-title {
  flex: 1;
  position: relative;
  color: $whiteColor;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(circle at bottom right, #575757, #1d1d1d);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: $lightFontWeight;
  font-family: "Baskervville";
  font-size: 2.75rem;
  line-height: 1.25;
  margin-bottom: 0;

  b {
    display: block;
    font-size: 4.5rem;
    font-family: $titleFont;
    text-transform: uppercase;
    letter-spacing: -2px;
    line-height: 1;
  }
}

.navigation {
  margin-right: -0.5rem;

  .unicon {
    :deep(svg) {
      width: 1.85rem;
      padding: 0.5rem;
      height: auto;
      fill: $lightGreyColor;

      &:hover {
        cursor: pointer;
        fill: $blackColor;
      }
    }
  }
}

.testimonial-wrapper {
  position: relative;
  z-index: 5;
  margin: 0 0 -10rem;
}

.testimonial {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  border-radius: 2rem;
  overflow: hidden;
  padding: 1rem;
  margin: 0 1.5rem;
  background: rgba(242, 241, 243, 0.75);
  border: 1px solid #ececec;
  box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  .testimonial-image {
    width: 30rem;
    border-radius: 1.5rem;
  }

  .info-wrapper {
    margin: 4rem 4rem 0;
    text-align: left;

    .testimonial-logo {
      display: block;
      width: 8rem;
      filter: invert(1);
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.5rem;
      color: $darkBlackColor;
      font-weight: $normalFontWeight;
      margin-bottom: 1.5rem;
    }

    .stat {
      margin-top: 1.5rem;

      h4 {
        color: $darkBlackColor;
        font-size: 2.75rem;
        margin-bottom: 0;

        span {
          font-size: 1rem;
          font-weight: $normalFontWeight;
          margin-left: 0.5rem;
          opacity: $lightOpacity;
        }
      }

      p {
        font-size: $mediumFontSize;
        color: $darkBlackColor;
        opacity: $lightOpacity;
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 3.5rem;
      font-size: $normalFontSize;
      color: $darkBlackColor;
      opacity: $lightOpacity;
      text-decoration: none;

      :deep(svg) {
        width: 1rem;
        height: auto;
        color: $darkBlackColor;
        opacity: $lightOpacity;
        margin: 0.25rem 0 0 0.5rem;
      }

      &:hover {
        opacity: 1;

        :deep(svg) {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .main-title {
    position: relative;
    font-size: 1.5rem;
    line-height: 1.1;
    z-index: 2;

    b {
      font-size: 2rem;
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  .info-title {
    margin: 0 0 2rem;
    text-align: center;

    .navigation {
      display: none;
    }
  }

  .testimonial {
    display: block;
    margin: 0 .5rem;

    .testimonial-image {
      width: 100%;
    }

    .info-wrapper {
      margin: 2rem 1rem;

      h3 {
        font-size: $normalFontSize;
      }

      .stat {
        h4 {
          font-size: 2rem;
        }

        p {
          font-size: $smallFontSize;
        }
      }

      a {
        margin-top: 1rem;
      }
    }
  }

  .carousel {
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
  }
}
</style>
        