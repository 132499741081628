<template>
  <div :class="`${selectedOption} pricing-wrapper`">
    <div class="title-gradient"></div>

    <!--START: Toggle options-->
    <p class="guarantee-disclaimer">
      30-day <span>money back guarantee</span> on all plans
    </p>
    <!--END: Toggle options-->

    <!--START: Pricing Tables-->
    <div class="pricing-tables">
      <div
        v-for="tier in plans[selectedOption].tiers"
        :key="tier._id"
        :class="{ popular: tier.mostPopular }"
        class="pricing-block"
      >
        <!--START: Badge-->
        <span v-if="tier.mostPopular" class="popular-badge">Most Popular</span>
        <!--END: Badge-->

        <!--START: Main Details-->
        <div class="plan-details">
          <span class="title">{{ tier.title }}</span>

          <!--START: Pricing-->
          <div v-if="!tier.contactSales" class="price-details">
            <span class="large-price"
              >${{ tier.pricing.originalPrice }} <em>per month</em></span
            >
          </div>
          <div v-else>
            <span class="large-price sales-price">Contact Us</span>
          </div>
          <!--END: Pricing-->

          <!--START: Ticket Volumes-->
          <div class="volume-details">
            <span class="ticket-volume"
              >+ {{ tier.contactSales ? "More than" : "Upto" }}
              <b>{{ tier.tickets }}</b>
              {{
                selectedOption == "selfService" ? "AI conversations" : "replies"
              }}
            </span>
            <span v-if="tier.additionalComment" class="additional-comment"
              >{{ tier.additionalComment }}
            </span>
            <span v-if="selectedOption == 'selfService'" class="billing-cycle"
              >7-day free trial. Paid monthly.</span
            >
            <span v-else class="billing-cycle"
              >Paid monthly. No contracts.</span
            >
          </div>
          <!--END: Ticket Volumes-->

          <button class="btn btn-black" @click="createAccount(tier.title)">
            Start Free Trial
          </button>
        </div>
        <!--END: Main Details-->

        <div class="features-toggle" @click="toggleFeatures(tier)">
          <unicon :name="tier.showFeatures ? 'minus' : 'plus'"></unicon>
          <span>{{ tier.showFeatures ? "Hide" : "View" }} Key Features</span>
        </div>

        <!--START: Inclusions list-->
        <div class="inclusions-list" :class="{ expand: tier.showFeatures }">
          <div v-for="item in plans[selectedOption].inclusions" :key="item.id">
            <span class="title">{{ item.title }}</span>
            <div
              v-for="feature in item.inclusions"
              :key="feature.id"
              class="feature"
            >
              <unicon name="check"></unicon>
              <span>{{ feature }}</span>
            </div>
          </div>
        </div>
        <!--END: Inclusions list-->
      </div>
    </div>
    <!--END: Pricing Tables-->
  </div>
</template>
          
<script>
export default {
  name: "PricingTable",
  props: {
    selectedOption: { type: String, default: "selfService" },
  },
  data() {
    return {
      plans: {
        selfService: {
          tiers: [
            {
              title: "Professional",
              tickets: 1000,
              additionalComment: " ",
              mostPopular: false,
              pricing: {
                originalPrice: 99,
                discountedPrice: 99,
              },
              showFeatures: false,
            },
            {
              title: "Organization",
              tickets: 4000,
              additionalComment: "+ Dedicated success manager",
              mostPopular: true,
              pricing: {
                originalPrice: 299,
                discountedPrice: 299,
              },
              showFeatures: false,
            },
            {
              title: "Enterprise",
              contactSales: true,
              tickets: 4000,
              additionalComment: "+ Dedicated success manager",
              showFeatures: false,
            },
          ],
          inclusions: [
            {
              title: "AI features",
              inclusions: [
                "AI replies made for e-commerce",
                "Maintain brand tonality",
                "AI powered search",
                "Automation and custom workflows",
                "100+ languages supported",
                "Guardrails for accuracy",
              ],
            },
            {
              title: "Self service features",
              inclusions: [
                "24/7 customer self service portal",
                "Track orders with AI summaries",
                "Cancellation requests",
                "Quick shortucts to resources",
                "AI powered search",
                "Agent handoff with context",
              ],
            },

            {
              title: "Shopify features",
              inclusions: [
                "Shopify products integrations",
                "Shopify products live-sync",
                "Shopify order tracking journeys",
                "Order cancellation requests",
                "Policies integration",
              ],
            },
            {
              title: "Other features",
              inclusions: [
                "Dedicated account manager",
                "AI training and optimization",
                "AI models powered by GPT 3.5 Turbo",
                "Chat summaries",
                "Category-wise workflows",
                "AI & chat analytics",
              ],
            },
          ],
        },
        helpdesk: {
          tiers: [
            {
              title: "Free",
              additionalComment: " ",
              tickets: 50,
              pricing: {
                originalPrice: 0,
              },
              showFeatures: false,
            },
            {
              title: "Starter",
              tickets: 250,
              additionalComment: "+ Dedicated Success Manager",
              mostPopular: false,
              pricing: {
                originalPrice: 49,
              },
              showFeatures: false,
            },
            {
              title: "Pro",
              tickets: 750,
              additionalComment: "+ Dedicated Success Manager",
              mostPopular: true,
              pricing: {
                originalPrice: 99,
              },
              showFeatures: false,
            },
            {
              title: "Plus",
              tickets: 2000,
              additionalComment: "+ Dedicated Success Manager",
              pricing: {
                originalPrice: 249,
              },
              showFeatures: false,
            },
          ],
          inclusions: [
            {
              title: "Zendesk features",
              inclusions: [
                "Agent workspace Zendesk app",
                "AI assisted replies",
                "Sentiment analysis",
                "Ticket summarization and intent analysis",
                "Auto-triaging",
                "Auto Responders",
                "Custom workflows",
                "Help center integration",
                "Live chat handoff",
                "Historic tickets integration",
              ],
            },
            {
              title: "Other features",
              inclusions: [
                "Dedicated account manager",
                "AI training and optimization",
                "AI models powered by GPT 4",
                "Chat summaries",
                "Category-wise workflows",
                "AI & chat analytics",
              ],
            },
          ],
        },
      },
      toggleOptions: [
        {
          title: "Self-Service AI Chat",
          value: "selfService",
        },
        {
          title: "Zendesk AI App",
          value: "helpdesk",
        },
      ],
    };
  },
  computed: {},
  methods: {
    createAccount(planType) {
      this.trackAnalyticsEvent("demo_btn", {
        source: "pricing-zendesk",
        plan: planType,
      });

      this.$store.commit("updateDemoData", {
        source: `Pricing Zendesk - ${planType}`,
        contactType: planType == "Enterprise" ? "sales" : "register",
      });

      if (this.selectedOption === "selfService") {
        this.$router.push({ name: "CreateAccount" });
      } else {
        window.open(
          "https://www.zendesk.com/in/marketplace/apps/support/986669/macha-ai-support/",
          "_blank"
        );
      }
    },

    // Check to see if small price needs to be shown
    hasSmallPrice(tier) {
      if (tier.pricing.discountedPrice) return true;
      return false;
    },

    // Return the small price
    getSmallPrice(tier) {
      return tier.pricing.originalPrice;
    },

    // Return the large price
    getLargePrice(tier) {
      if (tier.pricing.discountedPrice) return tier.pricing.discountedPrice;
      return tier.pricing.originalPrice;
    },

    // Toggle features on mobile
    toggleFeatures(plan) {
      plan.showFeatures = !plan.showFeatures;
    },
  },
};
</script>
          
<style scoped lang="scss">
.title-gradient {
  background: linear-gradient(
    180deg,
    rgba(196, 224, 254, 0) 0%,
    rgb(219 212 239) 52%,
    rgba(196, 224, 254, 0) 100%
  );
  position: absolute;
  top: 0%;
  left: -20%;
  height: 70vh;
  width: 140%;
  opacity: 0.75;
  filter: blur(10px);
  transform: translate3d(0, 0, 0);
}

.pricing-wrapper {
  position: relative;
  margin-top: 0rem;
}

.pricing-toggle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f1f1f1;
  border: 1px solid #e9e9e9;
  padding: 0.3rem 0.35rem;
  gap: 0.5rem;
  width: fit-content;
  margin: 1.5rem auto 0;
  border-radius: 0.75rem;
  z-index: 2;

  .toggle-option {
    padding: 0.75rem 1.15rem;
    border-radius: 0.5rem;
    color: #1b1b1b;
    font-weight: $mediumFontWeight;
    font-size: 1.15rem;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      color: darken(#7d7d7d, 15%);
    }

    &.selected {
      background-color: $purpleColor;
      border: 1px solid lighten($purpleColor, 35%);
      color: lighten($purpleColor, 45%);
      box-shadow: 0 0 1.7rem 0.15rem #d8c9ff;
    }
  }
}

.guarantee-disclaimer {
  display: block;
  position: relative;
  color: $greyColor;
  font-size: $normalFontSize;
  font-weight: $normalFontWeight;
  margin: 0.5rem auto 4rem;
  text-align: center;

  span {
    font-weight: $boldFontWeight;
  }
}

.pricing-tables {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 12.5%;
  gap: 1rem;
}
.pricing-block {
  position: relative;
  flex: 1;
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid #ececec;
  box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  .title {
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $normalFontSize;
  }

  .small-price {
    font-family: $titleFont;
    color: lighten($darkBlackColor, 55%);
    font-weight: $mediumFontWeight;
    font-size: 1.5rem;
    text-decoration: line-through;
    margin-right: 0.5rem;
  }

  .large-price {
    font-family: $titleFont;
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $mediumFontSize;
    font-size: 3.5rem;

    em {
      color: lighten($darkBlackColor, 15%);
      font-weight: $mediumFontWeight;
      font-size: 1.15rem;
      margin-left: -0.25rem;
    }

    &.sales-price {
      font-size: 2.5rem;
      line-height: 5.25rem;
    }
  }

  .volume-details {
    margin-top: -0.75rem;
    margin-bottom: 0.5rem;

    span {
      display: block;
      color: lighten($darkBlackColor, 35%);

      &.additional-comment,
      &.ticket-volume {
        font-size: $normalFontSize;
        color: lighten($darkBlackColor, 15%);
      }

      &.additional-comment {
        height: 1.4rem;
      }

      &.billing-cycle {
        margin-top: 1.5rem;
      }
    }
  }

  &.popular {
    border: 2px solid $emeraldColor;
    background: rgba(250, 255, 241, 0.55);
  }
}

.inclusions-list {
  .title {
    display: block;
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $normalFontSize;
    margin-top: 3.5rem;
  }
  .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;

    :deep(svg) {
      fill: $emeraldColor;
      width: auto;
      height: 1.15rem;
      margin-right: 1rem;
    }

    span {
      color: lighten($darkBlackColor, 35%);
    }
  }
}

.popular-badge {
  position: absolute;
  color: lighten($emeraldColor, 75%);
  background-color: $emeraldColor;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  top: -1rem;
  left: 1.5rem;
}

.features-toggle {
  display: none;
}

.helpdesk.pricing-wrapper {
  .pricing-tables {
    margin: 0 5%;
  }

  .large-price {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 800px) {
  .pricing-tables {
    margin: 0 1.5rem;
    display: block;

    .pricing-block {
      margin-bottom: 2rem;

      .title {
        font-size: 1rem;
      }

      .large-price {
        font-size: 3rem;
      }
    }
  }

  .pricing-toggle {
    margin: 1rem auto 0;

    .toggle-option {
      font-size: $smallFontSize;
      text-align: center;
    }
  }

  .features-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.5rem;

    :deep(svg) {
      height: auto;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }

  .inclusions-list {
    display: none;
    margin-top: -1.5rem;

    &.expand {
      display: block;
    }
  }

  .popular-badge {
    font-size: 1rem;
  }
}
</style>