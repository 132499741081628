<template>
  <div>
    <!--START: Header and Gradient-->
    <Header :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <!--START: Study Wraper-->
    <div class="study-wrapper">
      <!--START: Hero-->
      <div class="hero-wrapper">
        <div class="title-wrapper">
          <div class="title-block">
            <span class="page-label">Customer Story</span>
            <h1>Drink on.</h1>
          </div>
          <img
            src="@/assets/images/customers/corkcicle/logo.png"
            alt="Corkcicle logo"
          />
        </div>

        <div class="stats-wrapper">
          <div v-for="item in stats" :key="item" class="stat-item">
            <em>{{ item.stat }}</em>
            <p>{{ item.description }}</p>
          </div>
        </div>

        <span class="gradient-circle"></span>
      </div>
      <!--END: Hero-->

      <div class="hero-banner">
        <img
          src="@/assets/images/customers/corkcicle/hero.jpg"
          alt="Corkcicle Hero"
        />
      </div>

      <!--START: About-->
      <div class="about-wrapper">
        <div class="title-wrapper">
          <h3 class="title">About Corkcicle</h3>
          <a href="https://corkcicle.com/" target="_blank">
            <unicon name="external-link-alt"></unicon>
            <span>Visit Website</span>
          </a>
          <div class="tools-wrapper">
            <label>Customer Support Tools</label>
            <img src="@/assets/images/logos/zendesk.png" alt="Zendesk" />
          </div>
        </div>
        <div class="about-info">
          <div class="description">
            <p>
              Based in Orlando, Florida, Corkcicle's mission is to develop and
              make products that are stylish as well as sustainable. They create
              drinkware with an aim to make every sip an experience.
            </p>
            <p>Corkcicle has won</p>
            <ul>
              <li>2018 - Red Dot Design Award</li>
              <li>2016 - Red Dot Design Award</li>
              <li>Homeworld Business Top 25 Iconic Houseware Products</li>
            </ul>
          </div>
        </div>
      </div>
      <!--END: About-->

      <!--START: Overview-->
      <div class="overview-wrapper">
        <span class="gradient-circle"></span>
        <h3 class="title">Keeping it cool</h3>
        <p class="description">
          Corkcicle, a global brand operating in over 200 countries, sought to
          enhance their customer experience as their sales expanded rapidly
          worldwide. To meet the growing demand, their CX team aimed to offer
          24/7 customer support. They wanted to ensure that customers could not
          only track their orders but also receive prompt answers to
          product-related questions, helping them make purchase decisions
          quickly. The Corkcicle team collaborated closely with Team Macha to
          train the AI on frequently asked customer questions, enabling over 70%
          of inquiries to be resolved instantly. The partnership continues as
          they explore new ways to leverage AI for customer support through
          automation.
        </p>
        <ul class="overview-list">
          <li v-for="item in overviewList" :key="item" class="overview-item">
            {{ item }}
          </li>
        </ul>
      </div>
      <!--END: Overview-->

      <!--START: Challenge-->
      <div class="challenge-wrapper">
        <h3 class="title">The Challenge</h3>
        <div class="description">
          <p>
            Corkcicle is a company that thrives on innovation. As their customer
            base expanded globally, bringing in significant revenue growth year
            after year, their customer service team faced increasing demands. To
            <b
              >maintain their high standards of customer service, Corkcicle
              sought a solution that could scale with their growth while keeping
              pace with their innovative spirit.</b
            >
          </p>
          <p>
            Beyond the usual post-purchase inquiries, a frequent topic of
            customer contact is seeking product advice. With a diverse range of
            thoughtfully designed products, customers want to ensure they choose
            the perfect product. To address this, Corkcicle leveraged their top
            support agents to train Macha AI's knowledge base, ensuring
            customers receive the best guidance possible.
          </p>
        </div>
        <img
          src="@/assets/images/customers/corkcicle/asset.png"
          alt="Corkcicle Bottle"
          class="asset"
        />
      </div>
      <!--END: Challenge-->

      <!--START: Solution-->
      <div class="solution-wrapper">
        <h3 class="title">The Solution</h3>
        <div class="solutions-list">
          <div v-for="item in solutionsList" :key="item" class="solution-item">
            <div class="title-wrapper">
              <unicon :name="item.icon" />
              <h3>{{ item.title }}</h3>
            </div>

            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
      <!--END: Solution-->
    </div>
    <!--END: Study Wraper-->

    <div class="standard-footer">
      <!--START: Onboarding-->
      <div class="onboarding-wrapper">
        <div class="title-gradient"></div>

        <h2 class="main-title">
          Go live effortlessly with our
          <i>team supporting you at every step</i>
        </h2>

        <a
          href="https://cal.com/teammacha/macha-ai-demo"
          target="_blank"
          class="gradient-word gradient-title-header gradient-title-header-light gradient-btn"
        >
          Book a demo with our team
        </a>
      </div>
      <!--END: Onboarding-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
        
<script>
// Import components
import CustomerStory from "@/components/Home/CustomerStory";
import SupportStatistics from "@/components/Home/SupportStatistics";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "CorckcicleCaseStudy",
  data() {
    return {
      headerTheme: "dark",
      stats: [
        {
          stat: "80%",
          description: "of enquiries responded by AI",
        },
        {
          stat: "70%+",
          description: "AI conversations require no human agent ",
        },
        {
          stat: "90%",
          description: "reduction in WISMO and basic product queries",
        },
      ],
      overviewList: [
        "70%+ of all enquiries fully resolved without agents",
        "Deep Shopify integration to help customers with product and order queries",
        "On-brand and empathetic AI responses for a frustration free experience",
      ],
      solutionsList: [
        {
          icon: "exchange",
          title: "Seamless Automation",
          description:
            "Macha AI efficiently handles over 70% of incoming inquiries across various languages and regions, delivering comprehensive resolutions for the majority of cases. By leveraging Macha’s deep integrations with Shopify and Zendesk, customers receive fast, highly detailed, contextual, and empathetic responses.",
        },
        {
          icon: "bolt",
          title: "Generative AI",
          description:
            "Corkcicle harnesses Macha AI to deliver scalable personalization effortlessly across a wide range of channels and knowledge domains.",
        },
        {
          icon: "comment-alt",
          title: "Pre-Sales Queries",
          description:
            "When customers seek product advice, Macha AI evaluates all of Corkcicle’s products across various factors—such as style, usage, functionality and more—to recommend the ideal product for each customer.",
        },
        {
          icon: "lightbulb-alt",
          title: "Pushing Innovating",
          description:
            "Corkcicle and Macha AI have forged a unique partnership, meeting quarterly to strategize and develop new automations that continually enhance the customer experience.",
        },
        {
          icon: "cog",
          title: "Proactive Automation",
          description:
            "With the Macha AI analytics dashboard, the Corkcicle team can identify the most common customer queries and proactively suggest solutions as soon as visitors arrive on their website.",
        },
      ],
    };
  },

  components: {
    CustomerStory,
    SupportStatistics,
    Header,
    Footer,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "hero":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/customers.scss";

.challenge-wrapper {
  background-color: #ffe143;

  .title,
  .description p {
    color: #2c2811;
  }

  .asset {
    width: 18.5rem;
    right: 5rem;
  }
}

@media screen and (max-width: 800px) {
  .challenge-wrapper {
    padding-bottom: 10rem;
    
    .asset {
      right: 0;
      left: 0;
      bottom: -3rem;
      margin: auto;
      width: 6rem;
    }
  }
}
</style>