<template>
  <header :class="[theme, { 'mobile-menu': showMenu, dark: showMenu }]">
    <!--START: Logo Wrapper-->
    <div class="logo-wrapper">
      <div class="image-wrapper">
        <router-link to="/zendesk">
          <img src="@/assets/images/logo-white.png" class="logo" />
        </router-link>
      </div>
      <div
        class="menu-icon"
        :class="{ selected: showMenu }"
        @click="toggleMenu()"
      >
        <span></span>
        <span></span>
      </div>
    </div>
    <!--END: Logo Wrapper-->

    <menu>
      <div class="left-column">
        <router-link
          v-for="item in menu"
          :key="item.id"
          :to="{ path: item.link, hash: item.hash }"
        >
          {{ item.title }}
        </router-link>
      </div>
      <div class="right-column">
        <span class="disclaimer">No credit cards. No contracts.</span>
        <button class="btn btn-primary" @click="createAccount">
          <unicon name="external-link-alt"></unicon>
          <span>Add to Zendesk for free</span>
        </button>
        <a
          class="btn btn-border"
          href="https://cal.com/teammacha/zendeskappdemo"
          target="_blank"
        >
          Book a demo
        </a>
      </div>
    </menu>
  </header>
</template>
  
  <script>
export default {
  name: "GlobalHeader",
  props: {
    currentSection: String,
    theme: String,
  },
  data() {
    return {
      showMenu: false,
      menu: [
        {
          link: "/zendesk",
          hash: "",
          title: "Zendesk Widget",
          section: "hero",
        },
        {
          link: "/zendesk/pricing",
          hash: "",
          title: "Pricing",
        },
      ],
    };
  },
  methods: {
    createAccount() {
      const data = {
        event: "create_account",
        source: { source: "zendesk_header" },
      };
      this.$emit("createAccount", data);
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
  
  <style scoped lang="scss">
header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 1rem;
  left: 7%;
  width: calc(86% - 2rem - 2px);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background: rgba(73, 67, 89, 0.25);
  border: 1px solid #5950700d;
  box-shadow: 0 0.5rem 1rem -0.15rem rgba(42, 42, 42, 0.15);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  border-radius: 5rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  z-index: 10;

  .logo {
    display: block;
    width: 6.25rem;
    margin-left: 0.5rem;
    margin-top: -5px;
  }

  .right-column {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-align: right;

    .disclaimer {
      font-size: $smallestFontSize;
      color: $whiteColor;
      opacity: $lightOpacity;
    }
  }

  .btn {
    font-size: $smallFontSize;
    margin-left: 1rem;
  }
}

menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.menu-link,
menu a {
  color: #e2dfea;
  font-size: $smallFontSize;
  padding: 0.35rem 0.85rem;
  margin-left: 1rem;
  cursor: pointer;
  text-decoration: none;

  &.router-link-exact-active {
    color: #f9f9f9 !important;
    background: $darkBlackColor;
    border-radius: 0.5rem;
  }
}

menu {
  margin-left: 1rem;
}

header.light:not(.mobile-menu) {
  background: rgba(251, 251, 251, 0.7);

  .logo {
    filter: invert(1);
  }

  .right-column .disclaimer,
  .menu-link,
  menu a {
    color: #747474;
  }

  .btn-border {
    border: 1px solid #d8d8d8;

    &:hover {
      color: $whiteColor;
    }
  }
}

@media screen and (max-width: 800px) {
  header {
    display: block;
    left: 0.5rem;
    width: calc(100% - 3rem - 2px);
    padding: 0.5rem 1rem;
    border-radius: 1rem;

    .logo {
      width: 5.75rem;
    }
  }

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-wrapper {
      flex: 1;
    }
  }

  menu {
    display: none;

    .menu-link {
      display: block;
    }
  }

  .right-column {
    flex-direction: column-reverse !important;

    .btn {
      font-size: 1rem;
      width: 100%;
    }

    .disclaimer {
      margin-top: 0.25rem;
      font-size: $smallFontSize !important;
    }
  }

  .menu-icon {
    margin-left: 1rem;
    margin-right: 0.5rem;
    padding: 0.5rem 0;

    span {
      display: block;
      height: 2px;
      width: 1.5rem;
      margin: 0;
      border-radius: 0.5rem;
      background-color: $whiteColor;
      transition: all 0.3s;

      &:first-child {
        transform: rotate(0deg);
        transform-origin: top right;
        margin-bottom: 0.5rem;
      }
      &:last-child {
        transform: rotate(0deg);
        transform-origin: bottom right;
      }
    }

    &.selected {
      span {
        &:first-child {
          margin-bottom: 0.9rem;
          transform: rotate(-45deg);
        }
        &:last-child {
          transform: rotate(45deg);
        }
      }
    }
  }

  header.light:not(.mobile-menu) {
    .menu-icon {
      span {
        background-color: $greyColor;
      }
    }
  }

  .mobile-menu {
    top: 0;
    left: 0;
    padding: 1.25rem 1.5rem;
    border-radius: 0;
    background: rgba(2, 2, 2, 0.9) !important;

    menu {
      transition: none;
      position: fixed;
      display: block;
      top: 4.5rem;
      left: -1px;
      padding: 2rem 2rem 0;
      width: calc(100% - 4rem + 2px);
      height: calc(100vh - 6.5rem);
      background: rgba(21, 21, 21, 0.98);
      box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
      backdrop-filter: blur(15px);
      transform: translate3d(0, 0, 0);
      margin: 0;

      a {
        color: #797979 !important;
        display: block;
        font-weight: normal;
        font-size: 1.25rem;
        margin: 0 0 1.5rem;
        padding: 0 0 1.5rem;
        border-bottom: 1px solid #222;

        &.router-link-exact-active {
          color: $whiteColor !important;
          background: none;
          font-weight: bold;
        }
      }

      .btn-border {
        border: none;
        margin-bottom: 0;
      }
    }
  }

  menu a.router-link-exact-active {
    background: none;
    border-radius: 0;
    color: $purpleColor !important;
    font-weight: 500;
  }
}
</style>
  