<template>
  <div>
    <!--START: Header and Gradient-->
    <Header :theme="headerTheme"></Header>
    <!--END: Header and Gradient-->

    <!--START: Study Wraper-->
    <div class="study-wrapper">
      <!--START: Hero-->
      <div class="hero-wrapper">
        <div class="title-wrapper">
          <div class="title-block">
            <span class="page-label">Customer Story</span>
            <h1>Drive on.</h1>
          </div>
          <img
            src="@/assets/images/customers/subimods/logo.png"
            alt="Subimods logo"
          />
        </div>

        <div class="stats-wrapper">
          <div v-for="item in stats" :key="item" class="stat-item">
            <em>{{ item.stat }}</em>
            <p>{{ item.description }}</p>
          </div>
        </div>

        <span class="gradient-circle"></span>
      </div>
      <!--END: Hero-->

      <div class="hero-banner">
        <img
          src="@/assets/images/customers/subimods/hero.jpg"
          alt="Subimods Hero"
        />
      </div>

      <!--START: About-->
      <div class="about-wrapper">
        <div class="title-wrapper">
          <h3 class="title">About Subimods</h3>
          <a href="https://subimods.com/" target="_blank">
            <unicon name="external-link-alt"></unicon>
            <span>Visit Website</span>
          </a>
          <div class="tools-wrapper">
            <label>Customer Support Tools</label>
            <img src="@/assets/images/logos/zendesk.png" alt="Zendesk" />
          </div>
        </div>
        <div class="about-info">
          <div class="description">
            <p>
              Subimods is the leading supplier of aftermarket and performance
              parts for Subaru vehicles in North America. They offer a over a
              selection of over 10000 parts and accessories for various Subaru
              models, including the WRX, STI, and Legacy. They are headquartered
              in Connecticut, United States.
            </p>
          </div>
        </div>
      </div>
      <!--END: About-->

      <!--START: Overview-->
      <div class="overview-wrapper">
        <span class="gradient-circle"></span>
        <h3 class="title">Keep the wheels moving</h3>
        <p class="description">
          Despite the challenges of delivering Subaru parts that are a mix of
          in-stock and shipped directly from manufacturer, Subimods managed to
          successfully achieve high customer satisfaction rates with the help of
          syncing Macha AI's systems with their order management system.
        </p>
        <ul class="overview-list">
          <li v-for="item in overviewList" :key="item" class="overview-item">
            {{ item }}
          </li>
        </ul>
      </div>
      <!--END: Overview-->

      <!--START: Challenge-->
      <div class="challenge-wrapper">
        <h3 class="title">The Challenge</h3>
        <div class="description">
          <p>
            Subimods operates in a highly competitive market, where staying
            ahead of the competition requires constant innovation and customer
            satisfaction. One of the key strategies Subimods employs is offering
            an extensive range of products. This diverse inventory includes both
            items that are readily available in their warehouse and others that
            need to be shipped directly from the manufacturer.
          </p>
          <p>
            A common scenario for customers involves placing orders that contain
            a mix of in-stock items and those requiring manufacturer shipment.
            While in-stock items are shipped quickly, the items sourced directly
            from manufacturers often have longer lead times, leading to
            potential anxiety and uncertainty for customers about their order
            status. The Subimods team knew they needed an innovative solution to
            ensure their customers are kept informed about the status of their
            orders, minus the uncertainty.
          </p>
        </div>
        <img
          src="@/assets/images/customers/subimods/asset.png"
          alt="Subimods Rim"
          class="asset"
        />
      </div>
      <!--END: Challenge-->

      <!--START: Solution-->
      <div class="solution-wrapper">
        <h3 class="title">The Solution</h3>
        <div class="solutions-list">
          <div v-for="item in solutionsList" :key="item" class="solution-item">
            <div class="title-wrapper">
              <unicon :name="item.icon" />
              <h3>{{ item.title }}</h3>
            </div>

            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
      <!--END: Solution-->

      <!--START: Quote-->
      <div class="quote-wrapper">
        <em>“</em>
        <div class="quote">
          <p>
            AI is one of the greatest achievements of our generation, but it
            requires the right craftsmanship and maintenance to be effective.
            The Macha team excels at this. From the start, they helped us
            integrate AI into our business model, beginning with automating
            routine customer support tasks like answering common questions. This
            freed up our team to focus on more complex inquiries without
            sacrificing customer service.
          </p>
          <p>
            Macha's team has been responsive, customizing and optimizing their
            platform to meet our needs. They genuinely listen to our feedback
            and quickly implement solutions—something rare in today’s fast-paced
            tech world.
          </p>
        </div>
        <div class="profile-wrapper">
          <img
            src="@/assets/images/customers/subimods/profile.jpg"
            alt="Nick Nazzaro"
          />
          <div class="profile">
            <h3>Nick Nazarro</h3>
            <span>Founder & CEO</span>
          </div>
        </div>
      </div>
      <!--END: Quote-->
    </div>
    <!--END: Study Wraper-->

    <div class="standard-footer">
      <!--START: Onboarding-->
      <div class="onboarding-wrapper">
        <div class="title-gradient"></div>

        <h2 class="main-title">
          Go live effortlessly with our
          <i>team supporting you at every step</i>
        </h2>

        <a
          href="https://cal.com/teammacha/macha-ai-demo"
          target="_blank"
          class="gradient-word gradient-title-header gradient-title-header-light gradient-btn"
        >
          Book a demo with our team
        </a>
      </div>
      <!--END: Onboarding-->

      <!--START: Customer Story-->
      <CustomerStory></CustomerStory>
      <!--END: Customer Story-->
    </div>

    <div id="statistics" class="white-background scroll-section">
      <!--START: Statistics-->
      <SupportStatistics></SupportStatistics>
      <!--END: Statistics-->

      <div class="gradient-circle"></div>
    </div>

    <!--START: Header and Gradient-->
    <Footer></Footer>
    <!--END: Header and Gradient-->
  </div>
</template>
          
  <script>
// Import components
import CustomerStory from "@/components/Home/CustomerStory";
import SupportStatistics from "@/components/Home/SupportStatistics";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "SubimodsCaseStudy",
  data() {
    return {
      headerTheme: "dark",
      stats: [
        {
          stat: "70%",
          description: "of enquiries responded by AI",
        },
        {
          stat: "80%+",
          description: "AI conversations require no human agent ",
        },
        {
          stat: "85%",
          description: "reduction in WISMO and follow-up queries ",
        },
      ],
      overviewList: [
        "80%+ of all enquiries fully resolved without agents",
        "Custom API sync with internal order management system to provide accurate tracking information",
        "On-brand and highly technical information to help customers reach a purchase decision",
      ],
      solutionsList: [
        {
          icon: "exchange",
          title: "Seamless Integration",
          description:
            "Subimods integrated Macha AI with their internal order management system. This helped customers identify which items are being shipped from the manufacturer and provided transparent explanations for longer lead times. This enhanced order tracking experience, combined with accurate and timely information on pending items, reduced 'Where is my order?'' (WISMO) tickets by 90%",
        },
        {
          icon: "bolt",
          title: "Pre-Sales Queries",
          description:
            "Subimods specializes in selling highly technical automotive parts, which can sometimes be challenging for customers to navigate when selecting the right components for their Subaru vehicles. Recognizing this need, Subimods has implemented a robust support system that assists customers in making informed decisions. This system effectively handles at least 60% of technical inquiries, guiding customers through the complexities of their choices",
        },
        {
          icon: "comment-alt",
          title: "Pre-Sales Queries",
          description:
            "When customers seek product advice, Macha AI evaluates all of Corkcicle’s products across various factors—such as style, usage, functionality and more—to recommend the ideal product for each customer.",
        },
        {
          icon: "lightbulb-alt",
          title: "Custom Cancellation Workflow",
          description:
            "Subimods aimed to offer their customers the option to cancel certain orders, depending on the type of product and the time elapsed since the order was placed. However, they preferred not to allow customers to cancel orders directly. Instead, they wanted their team to review each cancellation request. The Macha AI team developed a custom workflow that enabled customers to select the items they wished to cancel and submit a cancellation request for the Subimods team to review.",
        },
        {
          icon: "cog",
          title: "Proactive Automation",
          description:
            "With the Macha AI analytics dashboard, the Subimods team can identify the most common customer queries and proactively suggest solutions as soon as visitors arrive on their website.",
        },
      ],
    };
  },

  components: {
    CustomerStory,
    SupportStatistics,
    Header,
    Footer,
  },
  async created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    // Header menu selection
    onScroll() {
      const scrollY = window.scrollY + 75;
      const sections = document.querySelectorAll(".scroll-section");

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (
          section.offsetTop <= scrollY &&
          section.offsetTop + section.offsetHeight >= scrollY
        ) {
          // Header theme styles
          switch (section.id) {
            case "hero":
              this.headerTheme = "light";
              break;
            default:
              this.headerTheme = "dark";
              break;
          }
        }
      }
    },
  },
};
</script>
          
  <style scoped lang="scss">
@import "@/assets/styles/shared/customers.scss";

.challenge-wrapper {
  background-color: #487eae;

  .title,
  .description p {
    color: #eff8ff;
  }

  .asset {
    width: 25rem;
    right: 5rem;
  }
}

@media screen and (max-width: 800px) {
  .challenge-wrapper {
    padding-bottom: 10rem;

    .asset {
      right: 0;
      left: 0;
      bottom: -2rem;
      margin: auto;
      width: 11rem;
    }
  }
}
</style>